.App-pages {
    height: 480px;
    width: 480px;
    perspective: 960px;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.App-page {
    color: white;
    height: 100%;
}

.App-page_red {
    background: #f44336;
}

.App-page_green {
    background: #4caf50;
}

.App-page_blue {
    background: #2196f3;
}

.App-page_orange {
    background: #ff9800;
}


.hold{
    width: 1000px;
    margin: auto;
    position: relative;
    display: block;
    height: 600px;
    margin-top:-130px
  }
  .shadow{
    position: absolute;
    width: 500px;
    left: 50%;
    top: 150px;
    height: 300px;
    box-shadow: 6px 4px 10px 0px rgba(0,0,0, 0.7);
  }
  .page-holder{
    height: 600px;
    width: 1000px;
    position: absolute;
    overflow: hidden;
    cursor: pointer;
  /*   background: yellow; */
  }
  a.link{
    text-decoration: none;
    color: white;
    background: black;
    padding: 10px 20px;
    float: right;
    right: 400px;
    position: relative;
  }
  .page{
    height: 200%;
    width: 100%;
    top: -50%;
    left: 0%;
    position: absolute;
    overflow: hidden;
    transform: rotateZ(-30deg);
    transition: transform 1s, left 1s;
    transform-origin: 100% 37.5%;
  }
  .page>.front,
  .page .back-holder,
  .page .back{
    height: 300px;
    width: 500px;
    position: absolute;
    background-size: cover;
    transition: transform 1s, left 1s;
    background-color: black;
    color: white;
  }
  .page>.front{
    top: 37.5%;
    left: 50%;
    transform: rotateZ(30deg) translateX(0);
    transform-origin: 100% 0%;
    box-shadow: inset 10px 0 10px -8px #000000;
  }
  .page .back-holder{
    left: 100%;
    top: 0;
    transition: left 1s, transform 1s;
    transform-origin: 0% 0%;
    transform: rotateZ(-60deg);
  }
  .page .back{
    left: 0%;
    top: 0;
    transform: rotateZ(0deg);
    transform-origin: 0% 0%;
    transition: top 1s, left 1s;
    box-shadow: inset -15px 0 22px -10px #000000;
  }
  .page .back:after{
    content: ' ';
    position: absolute;
    height: 100%;
    transform: rotateZ(-0.7deg);
    transform-origin: 0% 100%;
    width: 100%;
    box-shadow: 10px 8px 10px -1px rgba(0,0,0, 0.8);
  }
  .page-holder:hover .page{
    transform: rotateZ(-0deg);
    left: -50%;
  }
  .page-holder:hover .page>.front{
    transform: rotateZ(0deg) translateX(100%);
  }
  .page-holder:hover .back-holder{
    left: 0%;
    transform: rotateZ(0deg);
  /*   transform: rotateZ(0deg);
    top: 0; */
  }
  .page-holder:hover .back{
    left: -100%;
  }
  
  
  #insta{
    position: fixed;
    top: 50px;
    right: 80px;
    height: 80px;
    width: 80px;
    border-radius: 51px;
    overflow: hidden;
    box-shadow: 0 1px 12px -1px;
  }
  #insta a{
    display: block;
  }
  #insta img{
    width: 100%;
  }